<template>
  <div class="information_container" ref="informationlist">
    <item-scroll>
      <div class="information_body">
        <div class="information_title">
          <span class="global_primary_background_color">基本信息</span>
        </div>
        <div class="information_content">
          <div class="information_block">
            <div class="information_key">姓名</div>
            <div class="information_value">
              <div class="global-column-input">
                <el-input
                  v-model.trim="data.name"
                  @input="changeInput"
                  placeholder="请输入姓名"
                ></el-input>
              </div>
              <div class="inputtopblock" v-if="data.id"></div>
            </div>
          </div>
          <div class="information_block">
            <div class="information_key">省份</div>
            <div class="information_value">
              <el-select
                v-model="data.province"
                @change="changeProvince"
                placeholder="请选择省份"
              >
                <el-option
                  v-for="(item, index) in getProvinceList"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <div class="inputtopblock" v-if="data.id"></div>
            </div>
          </div>
          <div class="information_block">
            <div class="information_key">城市</div>
            <div class="information_value">
              <el-select
                v-model="data.city"
                @change="changeCity"
                placeholder="请选择城市"
              >
                <el-option
                  v-for="(item, index) in getCityList"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <div class="inputtopblock" v-if="data.id"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="information_body">
        <div class="information_title">
          <span class="global_primary_background_color">医院</span>
        </div>
        <div class="information_content">
          <div class="information_block">
            <div class="information_key">医院</div>
            <div class="information_value">
              <el-select
                v-model="data.hospital"
                @change="changeHospital"
                placeholder="请选择医院"
              >
                <el-option
                  v-for="(item, index) in getHospitalList"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <div class="inputtopblock" v-if="data.id"></div>
            </div>
          </div>
          <div class="information_block">
            <div class="information_key">科室</div>
            <div class="information_value">
              <el-select v-model="data.department" placeholder="请选择科室">
                <el-option
                  v-for="(item, index) in getDepartmentList"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <div class="inputtopblock" v-if="data.id"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="information_btngroup" v-if="!data.id">
        <el-button type="primary" @click.native="doCommit" round>
          <span class="information_button_name">确认绑定</span>
        </el-button>
      </div>
    </item-scroll>
  </div>
</template>
<script>
import ItemScroll from "@/components/unit/ItemScroll";
export default {
  name: "information",
  data: () => {
    return {
      requestTimer: {
        isRun: false,
        time: null,
        maxCount: 1000, // 停止输入多少毫秒开始请求
        startTime: 0, // 当前输入时间
        frameRate: 100, // 多少毫秒执行一次
      },
      data: {
        name: "",
        province: "",
        city: "",
        hospital: "",
        department: "",
      },
      config: {
        doctors: [],
        provinces: [],
        citys: [],
        hospitals: [],
        departments: [],
        dataItem: {},
      },
      loading: false,
    };
  },
  components: {
    ItemScroll,
  },
  
  computed: {
    getProvinceList() {
      let list = Object.keys(this.config.dataItem);
      return this.$tools.sortChinese(list);
    },
    getCityList() {
      let list = Object.keys(this.config.dataItem[this.data.province] || {});
      return this.$tools.sortChinese(list);
    },
    getHospitalList() {
      let list = Object.keys(
        this.config.dataItem[this.data.province]?.[this.data.city] || {}
      );
      return this.$tools.sortChinese(list);
    },
    getDepartmentList() {
      let list =
        this.config.dataItem[this.data.province]?.[this.data.city]?.[
          this.data.hospital
        ] || [];
      return this.$tools.sortChinese(list);
    },
  },
  created() {
    window.loadScrolls = this.initScroll;
    this.loadData();
  },
  methods: {
    loadData() {
      this.$axios
        .get(this.$urls.doctor.info)
        .then((res) => {
          this.complete = true;
          if (res.data && res.data.openid) {
            this.data = { ...this.data, ...res.data };
            document.getElementsByTagName("title")[0].innerHTML = "我的信息";
          } else {
            document.getElementsByTagName("title")[0].innerHTML = "绑定";
          }
        })
        .catch((err) => {});
    },
    
    changeInput() {
      this.requestTimer.startTime = Date.now();
      if (!this.requestTimer.isRun) {
        this.initRequestTimer();
      }
    },
    
    initRequestTimer() {
      if (this.requestTimer.time) {
        clearTimeout(this.requestTimer.time);
        this.requestTimer.time = null;
      }
      if (this.requestTimer.startTime == 0) {
        this.requestTimer.isRun = false;
        return;
      }
      if (
        Date.now() - this.requestTimer.startTime >
        this.requestTimer.maxCount
      ) {
        this.loadDoctor();
        this.requestTimer.startTime = 0;
      }
      this.requestTimer.isRun = true;
      setTimeout(() => {
        this.initRequestTimer();
      }, this.requestTimer.frameRate);
    },
    
    loadDoctor() {
      console.log(this.data)
      let url = this.$tools.getURL(this.$urls.doctor.register, {
        name: this.data.name,
      });
      console.log(url,this.data.name)
      this.$axios .get(url).then((res) => {
        this.initInfoData(res.data);
      }).catch((err) => {
        console.log(err);
      });
    },
    
    initBaseData() {
      this.data = {
        name: this.data.name,
        province: "",
        city: "",
        hospital: "",
        department: "",
      };
    },
    initInfoData(data) {
      if (data.length == 0) {
        this.config.dataItem = {};
        this.initBaseData();
        return;
      }
      // 数据格式
      //     {
      //     "省份"：{
      //         "城市":{
      //             "医院":["科室"]
      //         }
      //     }
      // }
      let dataItem = {};
      data.forEach((val) => {
        dataItem[val.province] = dataItem[val.province] || {};
        dataItem[val.province][val.city] =
          dataItem[val.province][val.city] || {};
        dataItem[val.province][val.city][val.hospital] =
          dataItem[val.province][val.city][val.hospital] || [];
        if (
          !dataItem[val.province][val.city][val.hospital].includes(
            val.department
          )
        ) {
          dataItem[val.province][val.city][val.hospital].push(val.department);
        }
      });
      this.config.dataItem = dataItem;
      if (data.length == 1) {
        this.data = {
          name: data[0].name,
          province: data[0].province,
          city: data[0].city,
          hospital: data[0].hospital,
          department: data[0].department,
        };
      }
    },
    changeProvince(text, status) {
      let index = this.config.provinces.map((x) => x.name).indexOf(this.data.province);
      index = index == -1 ? 0 : index;
      console.log(index);
      this.config.citys = this.config.provinces[index].sub;
      if (!status) this.data.city = this.config.citys[0] ? this.config.citys[0].name : "";
      this.loadHospital("", status);
    },

    changeProvince(value) {
      this.data.city = "";
      this.data.hospital = "";
      this.data.department = "";
    },
    changeCity() {
      this.data.hospital = "";
      this.data.department = "";
    },
    changeHospital() {
      this.data.department = "";
    },
    initScroll() {
      this.$nextTick(() => {
        if (!this.scroll) {
          this.scroll = new BSscroll(this.$refs.informationlist, {
            click: true,
          });
        } else {
          this.scroll.refresh();
        }
      });
    },
    checkData() {
      if (!this.data.name) {
        this.$tips.error({ text: "请输入姓名" });
        return false;
      }
      if (!this.data.province) {
        this.$tips.error({ text: "请选择省份" });
        return false;
      }
      if (!this.data.city) {
        this.$tips.error({ text: "请选择城市" });
        return false;
      }
      if (!this.data.hospital) {
        this.$tips.error({ text: "请选择医院" });
        return false;
      }
      if (!this.data.department) {
        this.$tips.error({ text: "请选择科室" });
        return false;
      }
      return true;
    },
    doCommit() {
      if (!this.checkData() || this.loading) return;
      this.loading = true;
      this.$axios.post(this.$urls.doctor.info, this.data).then((res) => {
        setTimeout(() => { this.loading = false;  }, 1000);
        let openid = this.$tools.getCookie('openid')
        this.$tools.setCookie("openid",openid)
        this.$router.push({
          path: "/meetlist",
        });
      }).catch(err=>{
        setTimeout(() => { this.loading = false;  }, 1000);
      })
    },
  },
};
</script>
<style>
.information_container {
  height: 100%;
  position: relative;
  overflow: hidden;
  padding: 0 5.33vw;
  background: #f5f5f5;
}
.information_main {
  padding: 3vw 0 8vw;
}
.information_body {
  margin-top: 3.6vw;
}
.information_body:first-child {
  margin: 0;
}
.information_title {
  margin-top: 6vw;
  margin-bottom: 3vw;
  font-weight: 600;
}
.information_title > span {
  display: inline-block;
  height: 7.2vw;
  /* width:22.4vw; */
  padding: 0 6.93vw;
  border-radius: 1.06vw;
  /* background:#81304B; */
  color: #fff;
  text-align: center;
  line-height: 7.2vw;
}
.information_content {
  background: #fff;
  border-radius: 1.06vw;
}
.information_block {
  height: 12.26vw;
  padding: 0vw 0.93vw 0vw 2.79vw;
  display: flex;
}
.information_key {
  flex: 18.93vw 0 0;
  justify-content: flex-start;
  text-align: right;
  display: flex;
  align-items: center;
  height: 100%;
  color: #666;
}
.information_value {
  flex: 1 0 0;
  height: 100%;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  position: relative;
  /* box-sizing:content-box; */
}
.information_top_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.information_value input {
  border: none;
  padding: 0;
  height: 5.6vw;
  line-height: 5.6vw;
  font-size: 4vw;
  position: relative;
  color: #000;
}

.information_value input::placeholder {
  color: #aaa;
}
.information_value_err input,
.information_value_err input::placeholder {
  color: #81304b;
}
.information_value i {
  line-height: 0;
}
.information_value .el-select {
  width: 100%;
}
.information_check_main .el-checkbox {
  margin-right: 7vw;
}
.information_check_main .el-checkbox:nth-child(4) {
  margin-right: 0;
}
.information_body .information_block:last-child .information_value {
  border-bottom: 1px solid #fff;
}
.information_select_tab {
  padding: 4.66vw 5.2vw;
}
.information_radio {
  /* margin-bottom:4.66vw; */
}
.information_other_radio {
  margin-top: 4.66vw;
  display: flex;
  background: #f0f0f0;
  padding: 3.2vw;
}
.information_checkbox_content {
  display: flex;
  align-items: center;
  height: 9.33vw;
}
.information_checkbox_other {
  flex: 1 0 0;
  height: 9.33vw;
  line-height: 9.33vw;
  background: #f2f2f2;
  display: flex;
  align-items: center;
  padding: 0 4.53vw;
  margin-left: 4.63vw;
}
.information_checkbox_content input {
  height: 6vw;
  line-height: 6vw;
  border: 0;
  padding: 0;
  margin: 0;
  background: #f2f2f2;
}
.information_radio_msg {
  height: 8.93vw;
}
.information_radio_msg:nth-child(2) {
  border-bottom: 1px solid #e6e6e6;
}
.information_agreement_main {
  font-size: 3.73vw;
  color: #666;
  min-height: 4.8vw;
  line-height: 4.8vw;
}
.information_agreement {
  display: flex;
  align-items: flex-start;
  margin-top: 4.86vw;
}
.information_agreement .el-checkbox {
  flex: 4.8vw 0 0;
}
.information_btngroup .el-button.is-round {
  width: 100%;
  font-size: 4.4vw;
  padding-top: 2vw;
  padding-bottom: 2vw;
  letter-spacing: 1px;
}
.information_btngroup button {
  width: 100%;
}
.information_btngroup button > span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.information_btngroup img {
  width: 5.86vw;
  margin-right: 0.5vw;
}
.information_button_name {
  display: inline-block;
}
.information_btngroup {
  /* height:11.73vw; */
  /* margin-top:18vw; */
  display: flex;
  align-items: center;
  /* position: absolute; */
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 5.33vw;
}
.information_btn_middle {
  vertical-align: middle;
}
.information_value_text {
  flex: 16vw;
  color: #fff;
  font-size: 3vw;
  letter-spacing: 0.5vw;
}
.information_value_text > span {
  padding: 0.5vw 1vw;
  border-radius: 1.06vw;
  background: #81304b;
}
.information_value_text_second {
  flex: 16vw;
  color: #999;
  font-size: 3.2vw;
  letter-spacing: 0.5vw;
}
.information_value_err input {
  color: #81304b;
}
.information_input_radio {
  line-height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  width: 100%;
}
.information_other_key {
  font-size: 3.73vw;
  display: flex;
  align-items: center;
  height: 5vw;
}
.information_other_value {
  height: 5vw;
  display: flex;
  align-items: center;
}
.information_other_value .el-radio {
  font-weight: 500;
}
.information_book_name {
  /* text-decoration:underline */
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.information_none input {
  color: #aaa;
}
.information_tips {
  background: #fff;
  /* padding: 4vw; */
  line-height: 1.5;
  /* font-weight: 500; */
  border-radius: 1.06vw;
  word-break: break-all;
  min-height: 30vw;
  position: relative;
  color: #6a6a6a;
  padding: 4vw;
}
.information_tips_sign {
  text-align: right;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50vw;
  position: absolute;
  bottom: 5vw;
  right: 0;
}
.information_tips_sign img {
  width: 15vw;
  height: 15vw;
}
.information_register_tips {
  display: inline-block;
  width: 100%;
  height: auto;
  margin-bottom: 4vw;
}
/* .information_tips img{
  width:20vw;
  height:20vw;
} */
.information_traduce {
  position: relative;
  /* display: flex; */
}
.information_container .inputtopblock {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
</style>
